import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'menu', 'popover']

  buttonTarget!: HTMLElement
  menuTarget!: HTMLElement
  popoverTarget!: HTMLElement

  connect() {
    this.hideOnOutsideClick = this.hideOnOutsideClick.bind(this)
    document.addEventListener('click', this.hideOnOutsideClick)
  }

  toggle(event: Event) {
    event.stopPropagation()
    this.menuTarget.classList.toggle('hidden')
  }

  hideOnOutsideClick(event: MouseEvent) {
    if (!this.element.contains(event.target as Node)) {
      this.menuTarget.classList.add('hidden')
    }
  }

  exportDocx(event: Event) {
    event.preventDefault()
    this.popoverTarget.classList.remove('hidden')

    // get the journey id from the URL /journeys/:id
    const path = window.location.pathname.split('/')
    const journeyIndex = path.indexOf('journeys')
    const journeyId = path[journeyIndex + 1]

    // get the params values from the URL
    const urlParams = new URLSearchParams(window.location.search)
    const showInstructions = urlParams.get('show_instructions') || 'false'
    const showFlaggedSection = urlParams.get('show_flagged_section') || 'true'
    const image_size = urlParams.get('image_size') || 'standard'

    const url = `/journeys/${journeyId}/docx`

    fetch(
      `${url}?show_instructions=${showInstructions}&show_flagged_section=${showFlaggedSection}&image_size=${image_size}`
    ).then(() => {
      setTimeout(() => {
        this.popoverTarget.classList.add('hidden')
      }, 4000) // delay of 4 seconds
    })
  }
}
